
.gameboard-panel {
}
.activity-panel-outer {

}
@media (min-width: 1440px) {
  .gameboard-panel {
    width: 20rem;
  }
}
@media (min-width: 1024px) {
  .gameboard-panel {
    width: 16rem;
    .gameboard-content {
      padding: 1rem 0rem 2rem;
    }
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .gameboard-panel {
    width: 14rem;
    font-size: 0.875rem;
  }
  .activity-panel-outer {
    font-size: 0.875rem;
  }
}

@media (min-width: 768px) {
  .gameboard-panel {
    .gameboard-content {
      padding: 1rem 0rem 2rem;
    }
  }
}
@media (max-width: 767px) {
  .gameboard-panel {
    width: 45vw;
    .gameboard-content {
      padding: 1rem 0.5rem 2rem;
    }
  }
  .activity-panel-outer {
    height: 5rem;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
}
@media (max-width: 639px) {
}