.pill {
  display: inline-block;
  background-color: #ededed;
  padding: 0.5rem 1.25rem;
  border-radius: 2rem;
  margin: 0 0.25rem 1rem;
  letter-spacing: 0.1rem;

  &.selected {
    background-color: #3b82f6;;
    color: #e9f1fd;
  }
}

a.pill:hover {
  filter: brightness(0.92);
}