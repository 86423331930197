table {
  width: 100%;
  background: white;
  margin-bottom: 1.25rem;
  // border: solid 1px #dddddd;
  border-style: solid;
  border-width: 1px;
  border-color: #dddddd;
  border-collapse: separate;
  border-spacing: 2px;

  thead {
    background: whitesmoke;
    tr {
      th, td {
        padding: 0.5rem 0.625rem 0.625rem;
        font-size: 0.875rem;
        font-weight: bold;
        color: #222222;
        text-align: left;
      }
    }
  }

  tr:nth-of-type(even) {
    background: #f9f9f9;
  }

  tr th, tr td {
    padding: 0.5625rem 0.625rem;
    font-size: 0.875rem;
    color: #222222;
    text-align: left;
  }

  thead tr th, tfoot tr th, tfoot tr td, tbody tr th, tbody tr td, tr td {
    display: table-cell;
    line-height: 1.125rem;
  }
}