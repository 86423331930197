$blue-letter-bg: #2a73ad;
$white-on-blue: #f9fcff;

$off-black: #363534;
$table-background: #ffffff; 

body {
  background-color: #eceae8;
}

.letter.tile {
  aspect-ratio: 1 / 1;
	border: 1px solid $off-black;
	width: 3rem;
	height: 3rem;
	width: 100%;
	color: $off-black;
	font-size: 2rem;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
  text-transform: uppercase;

	&.selected {
		color: $white-on-blue;
		background-color: $blue-letter-bg;
	}
}

table.game-board {
	width: 100%;
	font-size: 2rem;
	line-height: 1;
	margin: 0 auto 1rem;
	border-collapse: collapse;
	tbody {
		border-collapse: collapse;
		tr {
			border-collapse: collapse;
			background-color: $table-background;

			td {
				aspect-ratio: 1 / 1;
				// width: 3rem;
				// height: 3rem;
				text-align: center;
				color: #090909;
				// border: 1px solid #9a938d;
				border: 1px solid #2e2e2e;
				border-collapse: collapse;
				position: relative;

				.letter {
					color: $off-black;
					font-size: 2rem;
					font-weight: 500;
					z-index: 20;
					
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.letter.tile {
					border: none;
				}
				&.wrongPosition {
					.letter.tile {
						background-color: $blue-letter-bg;
						color: $white-on-blue;
					}
				}
				&.correct {
					.letter.tile {
						background-color: #19a497;
						color: white;
					}
				}
				&:hover {
					color: #222;
					border: 1px solid #222;
					cursor: pointer;
				}
				
				div.coords {
					font-size: 75%;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					z-index: 1;
				}
			}
		}
		tr:nth-of-type(even) {
			background-color: $table-background;
		}
	}
}

@media (min-width: 1440px) {
	table.game-board {
		width: 16rem;

		tbody {
			tr {
				td {
					width: 3rem;
					height: 3rem;
					
					.letter {
						// font-size: 1rem;
					}
					.letter.tile {
						height: 3rem;
					}
				}
			}
		}
	}
}
@media (min-width: 1024px) {
	table.game-board {
		width: 14rem;

		tbody {
			tr {
				td {
					width:  2.25rem;
					height: 2.25rem;
					
					.letter {
						// font-size: 1rem;
					}
					.letter.tile {
						height: 2.25rem;
					}
				}
			}
		}
	}
}
@media (min-width: 768px) and (max-width: 1023px) {
	table.game-board {
		width: 12rem;

		tbody {
			tr {
				td {
					width: 2rem;
					height: 2rem;
					
					.letter {
						font-size: 1.25rem;
					}
					.letter.tile {
						height: 2rem;
					}
				}
			}
		}
	}
	
}
@media (min-width: 640px) and (max-width: 767px) {
	
}
@media (max-width: 639px) {
	table.game-board {
		font-size: 1rem;
		line-height: 1;
		margin: 0 auto 1rem;
		tbody {
			tr {
				td {
					
					// width: 1.25rem;
					// height: 1.25rem;
					
					.letter {
						font-size: 1rem;
					}
					.letter.tile {
						width: 100%;
						aspect-ratio: 1 / 1;
						// height: 1.25rem;
						font-size: 1rem;
					}
				}
			}
		}
	}
}

.letter-drawer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	margin-bottom: 4rem; // For ending above bottom floating nav
	
	.letter.tile {
		margin: 0 0.5rem 0.5rem 0;
		position: relative;
		// transition: top 2s, left 2s;
		// transition-property: left;
		// transition-duration: 4s;
		transition: all 0.1s ease-out;
		
		&:hover {
			margin: 0.125rem 0.375rem 0.375rem 0.125rem;
			// top: 0.125rem;
			// left: 0.125rem;
			// background-color: pink;
		}
	}
}